import { useState,useEffect } from "react";
// reactstrap components
import { Input, FormGroup, Row, Col, Card,CardTitle,Button } from "reactstrap";
import update from 'immutability-helper';

const MemberDetail = (props) => { 
    
    const basicBen = [
            "Room and board",   
            "Miscellaneous charges",
            "Attending doctor's visit fee",  
            "Specialist's fee",  
            "Intensive care",  
            "Surgeon's fee",  
            "Anaesthetist's fee",  
            "Operating theatre charges",  
            "Prescribed Diagnostic Imaging Tests",  
            "Prescribed Non-surgical Cancer Treatments",  
            "Pre- and post-Confinement/Day Case Procedure outpatient care",
    ]

    const enhancedBen = [
        "Donor benefit",
        "Emergency outpatient treatment",
        "Home Nursing",
        "Hospice and palliative care benefit",
        "Outpatient kidney dialysis  ",
        "Rehabilitative care  ",
    ]

    const embedBen = [
        "Accidental Death Benefit",
        "Cash Benefit  ",
        "Check-up Benefit  ",
        "Critical Illness Benefit  ",
        "Dental Benefit  ",
        "Life / (Compassionate) Death Benefit  ",
        "Medical Negligence Benefit  ",
        "Optical Benefit",  
        "Outpatient Care Benefit  ",
        "Outpatient Maternity Benefit  ",
        "Personal Accident Benefit",   
        "Second Opinion Service ", 
        "Vaccination Benefit",  
        "Worldwide Emergency Assistance Services ", 
    ]

    const [isShowBen,setShowBen] = useState(false)

    const updateMember = (e)=>{
        let key = e.target.name
        let value = e.target.value
        if(key){
            let newMember = {...props.member}
            newMember[key] = value
            props.updateMember(newMember)
        }
    }

    return(
    <div id={props?.uuid ?? ""}
        style={{animation: "slide-up 0.3s ease"}}
    >
        <Card body className="mb-3">
            <CardTitle>
                <h3>
                    Member # {(props?.idx ?? 0)+1}
                    <Button color="danger" className="ml-3" size="sm"
                        onClick={()=>{
                            if(props?.removeMember){
                                props.removeMember(props?.member?.uuid)
                            }
                        }}
                    >
                    <i className="fa fa-trash mr-1"></i>
                        Remove
                    </Button>
                </h3>
            </CardTitle>
            <Row className="bg-secondary rounded py-3">
                <Col md="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Family Name
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-lastname"
                            placeholder="Family Name"
                            type="text"
                            name="lastName"
                            value={props?.member?.lastName ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            First Name
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-firstname"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            value={props?.member?.firstName ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Middle Name
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-middlename"
                            placeholder="Middle Name"
                            type="text"
                            name="midName"
                            value={props?.member?.midName ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Sex
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-sex"
                            type="select"
                            name="sex"
                            value={props?.member?.sex ?? ""}
                            onChange={updateMember}
                        >
                            <option value="">Select</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                             Date of birth
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-dob"
                            placeholder="MM/DD/YYYY"
                            type="text"
                            name="dob"
                            value={props?.member?.dob ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Occupation
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-occupation"
                            placeholder="Occupation"
                            type="text"
                            name="occupation"
                            value={props?.member?.occupation ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Passport/ID no.
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-passport"
                            type="text"
                            name="passport"
                            value={props?.member?.passport ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                            Email
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder=""
                            type="text"
                            name="email"
                            value={props?.member?.email ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>

                <Col md="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                             Country of residence
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-residence"
                            type="text"
                            name="countryResidence"
                            value={props?.member?.countryResidence ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                             Country of citizenship
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-citizenship"
                            type="text"
                            name="countryCitizenship"
                            value={props?.member?.countryCitizenship ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                             Effective date
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-effDate"
                            type="text"
                            placeholder="MM/DD/YYYY"
                            name="effDate"
                            value={props?.member?.effDate ?? ""}
                            onChange={updateMember}
                        />
                    </FormGroup>
                </Col>
                <Col md="6" className="pt-3">
                    <Button block color="primary" className="mt-3"
                     onClick={()=>{
                         setShowBen(!isShowBen)
                     }}
                    >
                        {isShowBen ? "Hide":"Show"} Benefit Setting
                    </Button>
                </Col>
                <div className="w-100"/>
                {isShowBen && <>
                <h6 className="heading-small text-muted mb-4 w-100">Custom Basic Benefit</h6>
                {basicBen.map((ben)=>(<Col md={ben.length > 40 ?"6":"4"}>
                    <FormGroup>
                        <label
                            className="form-control-label"
                        >
                             {ben}
                        </label>
                        <Input
                            className="form-control-alternative"
                            type="text"
                        />
                    </FormGroup>
                </Col>))}
                <div className="w-100"/>
                <h6 className="heading-small text-muted mb-4 w-100">Enhanced Benefit</h6>
                {enhancedBen.map((ben,idx)=>(<Col md={ben.length > 40 ?"6":"4"}>
                    <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id={"enh-"+idx}
                            type="checkbox"
                          />
                          <label className="custom-control-label" htmlFor={"enh-"+idx}>
                            {ben}
                          </label>
                        </div>
                </Col>))}
                <div className="w-100"/>
                <h6 className="heading-small text-muted mb-4 w-100">Embed Benefit</h6>
                {embedBen.map((ben,idx)=>(<Col md={ben.length > 38 ?"6":"4"}>
                    <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id={"em-"+idx}
                            type="checkbox"
                          />
                          <label className="custom-control-label" htmlFor={"em-"+idx}>
                            {ben}
                          </label>
                    </div>
                </Col>))}
                </>}
            </Row>
        </Card>
    </div>
)}

export default MemberDetail