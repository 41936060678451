/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState,useEffect } from "react";
import update from 'immutability-helper';
import { v4 as uuid } from 'uuid';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import MemberDetail from "components/AppForm/MemberDetail";
import Header from "components/Headers/Header.js";

const GpAppForm = () => {


  const [appForm,setAppForm] = useState({
                                        members : []
                                        })

  useEffect(() => {
    addMember();
  }, []);                                      

  const addMember = ()=>{
    let newAppForm = update(appForm,{members:{$push:[{uuid:uuid()}]}})
    setAppForm(newAppForm)
  }

  const removeMember = (uuid)=>{
    const mbrIdx = appForm.members.findIndex((m)=>(m.uuid === uuid))
    if(mbrIdx !== null){
      let newAppForm = update(appForm,{members:{$splice:[[mbrIdx,1]]}})
      setAppForm(newAppForm)
    }
  }

  const updateMember = (member)=>{
    const mbrIdx = appForm.members.findIndex((m)=>(m.uuid === member.uuid))
    if(mbrIdx !== null){
      let newAppForm = update(appForm,{members:{$splice:[[mbrIdx,1,member]]}})
      setAppForm(newAppForm)
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">GROUP MEDICAL APPLICATION FORM </h3>
                  </Col>
                  <Col className="text-right d-none" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    COMPANY DETAILS 
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Company / Policyholder Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-companyname"
                            placeholder="Company / Policyholder Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Business Registration Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bnum"
                            placeholder="Business Registration Number"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Billing Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-billAddr"
                            placeholder="Billing Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Company Industry
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-comIdy"
                            placeholder="Company Industry"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Company Representative
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Family Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lastname"
                            placeholder="Family Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-firstname"
                            placeholder="First Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Middle Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-middlename"
                            placeholder="Middle Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Title
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-title"
                            placeholder="Title"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Tel
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Tel"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="E-mail"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* ************************* */}
                  <h6 className="heading-small text-muted mb-4">Special Request(s)</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Special Request(s)</label>
                      <Input
                        className="form-control-alternative"
                        rows="4"
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                  <hr className="my-4" />
                  {/* ************************* */}
                  <h6 className="heading-small text-muted mb-4">PLAN SELECTION</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Product Plan</label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            >
                            <option>GP HOSP, SURG, SP RM, OP 100%, DT (PLAN 1) 20</option>                            
                            <option>GP HOSP, SUR, WARD, OP 80%, PLAN 3 18 (20)</option>
                            <option>GP HOSP, SUR, SEMI-PRI RB, PLAN 2 18 (20)</option>
                            <option>GP HOSP, SUR, SEMI-PRI RB, OP 80%, PLAN 2 18 (20)</option>
                            <option>GP HOSP, SUR, PRI RB, OP 100%, PLAN 1 18 (20)</option>
                            <option>GP HOSP, SURG BEN 120K, OP PLAN 1 17 (20)</option>
                            <option>GP HOSP, SURG (WARD), OP 100% (PLAN 2) 17 (20)</option>
                            <option>GP HOSP, SUR BEN 100K, OP 80% 17 (18), STD DT PLAN</option>
                            <option>GP HOSP, SURG (WARD), OP 80%, SD DT, VS (20)</option>
                            <option>GP DT 1K 100%</option>
                            <option>GP HOSP &amp; SURG 65K, OP 17 (20)</option>
                            <option>GP HOSP, SURG BEN 50K, OP 80% PLAN 3 (20)</option>                            
                          </Input>
                        </FormGroup>
                      </Col>
                      
                      <Col md="6">
                        <FormGroup>
                          <label>Policy Effective Date</label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            placeholder="MM/DD/YYYY"
                            />
                        </FormGroup>
                      </Col>
                      
                      <Col md="3">
                        <FormGroup>
                          <label>Number of Employees</label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            value={appForm.members.length ?? 0}
                            readOnly
                            />
                        </FormGroup>
                      </Col>
                      
                      <Col md="3">
                        <FormGroup>
                          <label>Upload Member List</label>
                          <Button block color="primary">
                            <i className="fa fa-file mr-3"></i>
                            Select File
                            </Button>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        {appForm.members.map((m,midx)=>(
                          <MemberDetail 
                            idx={midx} 
                            key={"mbr-"+midx} 
                            removeMember={removeMember}
                            updateMember={updateMember}
                            member={m}
                          />
                        ))}
                        
                      </Col>
                      <Col md="12" className="pb-3">
                        <Button color="primary" block
                          onClick={addMember}
                        >
                          + Add Member
                        </Button>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Eligibility / Employee Class</label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            />
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup>
                          <label>Quoted Premium (in USD)</label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            />
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup>
                          <label>Payment Frequency</label>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            />
                        </FormGroup>
                      </Col>

                    </Row>
                    </div>
                  <hr className="my-4" />
                  {/* ************************* */}
                  <h6 className="heading-small text-muted mb-4">DECLARATION BY POLICYHOLDER </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <Input
                          className="form-control-alternative mb-3"
                          rows="8"
                          type="textarea"
                          value={`
                          We (the Policyholder) hereby applies for a policy to be based on the statements and declare that, to the best of our 
                          knowledge and belief, all answers to the foregoing questions are correctly and accurately recorded, and they are 
                          full, complete and true. 
                           
                          We hereby declare and agree that the Policyholder shall have the authority to deal with, receive or request for 
                          information from the Company concerning the Insured Person(s) in relation to any claims or matters arising from 
                          the policy issued pursuant to this application.  We further agree that payment of any benefits hereunder to the 
                          Policyholder or Insured Person(s) in relation to all medical claims shall constitute a full discharge on the part of the 
                          Company in relation to such claims. 
                           
                          We further authorize the Company to provide our personal data including but not limited to health and details of 
                          the claims incurred to reinsurance companies with whom the Company has or proposes to have dealings or to any 
                          agent,  contractor  or third party  service  provider  who  provides  services  to  the  Company  in connection with  the 
                          operation of its business. `}
                          onChange={()=>{}}
                        />
                      </Col>

                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="customCheck1"
                            type="checkbox"
                          />
                          <label className="custom-control-label" htmlFor="customCheck1">
                            I agree to the declaration.
                          </label>
                        </div>
                      </Col>


                      <Col md="12">
                      <Button color="primary">Save</Button> <Button color="success">Submit</Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GpAppForm;
